import React, { useRef } from 'react';

import * as d3 from 'd3';

function singleStack(items, key) {
  const stack = [];
  let sum = 0;
  for (const item of items) {
    const l = [sum, item[key]];
    l.data = item;
    stack.push(l);
    sum += item.total;
  }
  return stack;
}

function Bar({ parentItem, x, y, width, height, hoverItemIndex, lastBar }) {
  const yScale = d3
    .scaleLinear()
    .domain([0, parentItem.total])
    .range([0, height]);

  const children = Object.values(parentItem.children);
  children.sort((a, b) => b.total - a.total);
  const series = singleStack(children, 'total');

  const bar = (
    <g
      style={{ opacity: lastBar ? 1 : 0.25, transition: 'opacity 0.5s' }}
      transform={`translate(${x},${y})`}
    >
      {series.map(([y, height], i) => (
        <rect
          key={i}
          style={{ transition: '0.3s' }}
          x={-5}
          y={yScale(y)}
          width={width}
          height={yScale(height)}
          fill={series[i].data.color}
          transform={hoverItemIndex === i ? 'scale(1.4,1)' : 'scale(1)'}
        />
      ))}
    </g>
  );
  return bar;
}

function BarViz({ budget, trail, hoverItemIndex }) {
  // We could use the size of the screen here.
  // This could also be hooked up to a resize event.
  const height = 600;

  let svgRef = useRef();

  const bars = [];
  let parentItem;
  let tx = 15;
  // Note the -1 here -- we want to start at the current item.
  for (let i = -1; i < trail.length; i++) {
    const budgetItemId = trail[i];
    if (i === -1) {
      parentItem = budget;
    } else {
      parentItem = parentItem.children[budgetItemId];
    }

    const lastBar = i === trail.length - 1;
    bars.push(
      <Bar
        key={budget}
        parentItem={parentItem}
        x={tx}
        y={0}
        width={10}
        height={height}
        hoverItemIndex={lastBar ? hoverItemIndex : -1}
        lastBar={lastBar}
      />
    );
    tx += 20;
  }

  return (
    <svg className="pt-5 sticky top-0" width={60} height={height} ref={svgRef}>
      {bars}
    </svg>
  );
}

export default BarViz;
