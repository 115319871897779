import ThemeCard from '../general/themeCard';
import React, {useEffect, useRef} from 'react';
import useIntl from '../../helpers/useIntl';
import HomeDetailStyle from '../../less/home-detail.module.less';
import {
  FormatNumberToLocaleString,
  FormatNumberToPercentage,
} from '../../helpers/numberFormat';
import PropTypes from 'prop-types';
import {motion} from 'framer-motion';

const HomeDetail = ({
                      budget,
                      onHeightChange,
                      onClick,
                      isMobile,
                      selectedCategory,
                      totalBudget,
                      hoveredItem,
                      onMouseEnter,
                      onMouseLeave,
                      animate
                    }) => {
  const intl = useIntl();
  const containerEl = useRef(null);
  const shouldAnimate = useRef(animate);
  let titleRef = useRef(null);
  let offset = 0;

  useEffect(() => {
    let ref = titleRef;
    setTimeout(() => {
      resize();
      ref.focus();
    }, 10)
  }, [budget]);

  if (!budget || !budget.children) {
    return <div/>;
  }

  const resize = () => {
    if (!containerEl || !containerEl.current || typeof window === `undefined`) {
      return;
    }
    onHeightChange(isMobile ? containerEl.current.offsetHeight + 80 : containerEl.current.offsetHeight + 150);
  };

  const sortByTotals = (budget) => {
    let budgetArr = [];
    for (let child in budget.children) {
      budgetArr.push(budget.children[child]);
    }

    return budgetArr.sort((a, b) => b.total - a.total);
  };

  let sortedBudget = sortByTotals(budget);
  const title = budget[`label_${intl.locale}`] || intl.formatMessage({id: `general_${selectedCategory}`});
  return (
    <motion.div
      key="home-detail"
      initial={{opacity: 0}}
      animate={{
        opacity: 1,
        transition: {
          duration: shouldAnimate.current ? 0.3 : 0,
          delay: shouldAnimate.current ? 1.3 : 0,
        },
      }}
      exit={{opacity: 1, transition: {duration: 0}}}
      ref={containerEl}
      className={HomeDetailStyle.container}>

      <div className={HomeDetailStyle.header}>
        <div className={HomeDetailStyle.title}>
          <h3 tabIndex={0} ref={(ref) => titleRef = ref}>
            {title}
          </h3>
          <span>€ {FormatNumberToLocaleString(budget.total)}
            <b className={HomeDetailStyle.percentage}>({FormatNumberToPercentage(budget.total, totalBudget)}%)</b>
        </span>
        </div>
        {
          budget[`description_${intl.locale}`] && (
            <p style={{marginBottom: 0}}>
              {intl.translate(budget, 'description')}
            </p>
          )
        }
      </div>

      {sortedBudget.map((item, index) => {
        let clickAble = !!(item && Object.keys(item.children).length);
        offset += item.total;
        item.offset = offset - item.total;
        return (
          <div style={{marginBottom: '1rem'}} key={index}>
            <ThemeCard total={budget.total}
                       isMobile={isMobile}
                       onClick={() => {
                         if (clickAble) {
                           onClick(item.category_id);
                         }
                       }}
                       onMouseEnter={() => onMouseEnter(item)}
                       onMouseLeave={() => onMouseLeave()}
                       onHeightChange={() => resize()}
                       hasChildren={!!clickAble}
                       hasReadMore={item.description_nl && item.description_fr !== null}
                       offset={item.offset}
                       color={item.color}
                       key={index}
                       title={item[`label_${intl.locale}`]}
                       description={item[`description_${intl.locale}`]}
                       amount={item.total}
                       showcases={Object.values(item.showcases)}
                       info={item.info}
                       highlighted={item?.category_id === hoveredItem?.category_id}
                       readMoreTranslation={intl.formatMessage({id: 'detail_readmore'})}
                       showChildrenTranslation={intl.formatMessage({id: 'detail_showchildren'})}
                       projectsTranslation={intl.formatMessage({id: 'detail_projects'})}/>
          </div>
        );
      })}
    </motion.div>
  );
};

HomeDetail.propTypes = {
  hoveredItem: PropTypes.object,
};

HomeDetail.defaultProps = {
  hoveredItem: {},
};

export default HomeDetail;
