import * as d3 from 'd3';

const euroLocale = d3.formatLocale({
  decimal: ',',
  thousands: '.',
  grouping: [3],
  currency: ['€ ', ''],
});

export const formatEuro = euroLocale.format('$,');
