import Layout from "../general/layout"
import SEO from "../general/seo"
import React from "react"
import FormatNumberToBillions from "../../helpers/numberFormat"
import useIntl from "../../helpers/useIntl"

const HomeLayout = props => {
  const intl = useIntl()
  const translatedHeroImage = intl.formatMessage({id: 'home_hero_image'});
  const {children, isDetailPage, searchableBudget, selectedCategory, budget, trail, categoryIdTrail, isMobile, selectedYear} = props

  let heroSubtitle, heroTitle

  let budgetItem = budget ? budget[selectedCategory || "expenses"] : null

  heroTitle =
    selectedCategory ? (
      selectedCategory === "expenses" ? intl.formatMessage({id: "home_hero_title_expenses"})
        : intl.formatMessage({id: "home_hero_title_revenue"})
    ) : intl.formatMessage({id: "home_hero_title"})

  heroSubtitle = isDetailPage && budget ?
    `${FormatNumberToBillions(
      selectedCategory === "expenses" ? budget.expenses.total : budget.revenue.total)}
       ${intl.formatMessage(
      {id: `home_hero_subtitle_${selectedCategory}`})} | ${selectedYear}` : intl.formatMessage({id: "home_hero_sub_title"})

  let breadcrumbs = []

  if (isMobile && isDetailPage) {
    breadcrumbs.push({
      label: intl.formatMessage({id: `general_${selectedCategory}`}),
      url: "/home/" + selectedCategory + "/",
    })
  }

  let trailCrumbs = categoryIdTrail.map(function (trailPath, i) {
    budgetItem = budgetItem && budgetItem.children ? budgetItem.children[trailPath] : {}

    const urlPortionIndex = trail.findIndex(
      (portion) => portion === trailPath.toString())

    return {
      ...budgetItem,
      label: intl.translate(budgetItem, "label"),
      url: `/home/${trail.slice(0, urlPortionIndex + 1).join("/")}/`,
    }
  })

  breadcrumbs = breadcrumbs.concat(trailCrumbs)
  return <Layout
    isMarked={true}
    heroFixedImage={translatedHeroImage}
    heroAltText={""}
    heroTitle={heroTitle}
    isMobile={isMobile}
    titleOffset={true}
    heroSubtitle={heroSubtitle}
    showBreadcrumbs={isDetailPage}
    searchableBudget={searchableBudget}
    breadcrumbs={breadcrumbs}>
    {children}
  </Layout>
}

export default HomeLayout
