import {Dropdown, Radio} from "antd";
import HomeStyle from "../../less/home.module.less";
import ControlsStyle from "../../less/controls.module.less";
import YearDropdownMenu from "./year-dropdown-menu";
import {DownOutlined, PieChartOutlined, TableOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import {useIntl} from "gatsby-plugin-intl";
import {navigate} from "@reach/router";

export default function Controls({
                                   isMobile,
                                   years,
                                   selectedYear,
                                   selectedCategory,
                                   setSelectedCategory,
                                   onChangeGraphType,
                                   selectedGraphType
                                 }) {
  const intl = useIntl();
  const [dropdownCollapsed, setDropdownCollapsed] = useState(false);

  const categorySelectionOptions = [
    {label: intl.formatMessage({id: 'general_revenue'}), value: 'revenue'},
    {label: intl.formatMessage({id: 'general_expenses'}), value: 'expenses'}
  ];
  const graphTypeSelectionOptions = [
    {
      label: <PieChartOutlined
        aria-label={intl.formatMessage({id: 'home_graphtype_pie'})}/>, value: 1
    },
    {
      label: <TableOutlined
        aria-label={intl.formatMessage({id: 'home_graphtype_table'})}/>,
      value: 2
    },
  ];

  function changeYear(year) {
    if (isMobile) {
      navigate(`/${intl.locale}/home/${year}/`)
    } else {
      navigate(`/${intl.locale}/home/${year}/${selectedCategory}/`)
    }
    setDropdownCollapsed(false);
  }

  return (
    <>
      {!isMobile ? (
        <div>
          <Radio.Group options={graphTypeSelectionOptions}
                       optionType='button'
                       buttonStyle='solid'
                       name={"type"}
                       onChange={onChangeGraphType}
                       value={selectedGraphType}>
          </Radio.Group>

          {selectedGraphType === 2 ? (
            <Radio.Group options={categorySelectionOptions}
                         optionType='button'
                         name={"category"}
                         buttonStyle='solid'
                         onChange={(e) => {
                           setSelectedCategory(e.target.value)
                         }}
                         value={selectedCategory}
                         className={ControlsStyle.categoryRadio}>
            </Radio.Group>
          ) : <div/>}
        </div>
      ) : <div/>}

      <div className={ControlsStyle.right}>
        <Dropdown className={HomeStyle.dropdown}
                  trigger={['click']}
                  onVisibleChange={(collapsed) => {
                    setDropdownCollapsed(collapsed)
                  }}
                  visible={dropdownCollapsed}
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                  disabled={years.length <= 1}
                  overlay={() => (
                    <YearDropdownMenu
                      onSelect={(year) => changeYear(year, selectedCategory)}
                      selectedCategory={selectedCategory}
                      years={years}/>
                  )}>
          <button className="ant-dropdown-link is-clear"
                  aria-expanded={dropdownCollapsed}
                  onClick={(e) => e.preventDefault()}>
            {selectedYear}
            {years.length > 1 && (
              <DownOutlined style={{margin: '0 0 0 10px'}}
                            role={''}
                            aria-label={''}/>
            )}
          </button>
        </Dropdown>
      </div>
    </>
  )
}


