export function makeSearchable(budget) {
  return [
    ...flattenBudget(budget.expenses, [], 'expenses'),
    ...flattenBudget(budget.revenue, [], 'revenue'),
  ]
}


function flattenBudget(budgetPart, container, mainCategory) {
  if (budgetPart.children) {
    Object.keys(budgetPart.children).forEach((key) => {
      container.push({
        ...budgetPart.children[key],
        parent: budgetPart,
        mainCategory,
        hasChildren: !!Object.keys(budgetPart.children[key].children).length,
      })

      delete container[container.length -1].children;

      flattenBudget(budgetPart.children[key], container, mainCategory)
    })
  }

  return container
}
