import React from "react";
import {Button, Table} from "antd";
import TableOverviewStyle from "../../less/table-overview.module.less"
import {formatEuro} from '../../helpers/currency';
import {useIntl} from "gatsby-plugin-intl";
import {defineMessages} from "react-intl";

export default function TableOverview({
                                        budget,
                                        selectedCategory,
                                        onClick
                                      }) {
  const intl = useIntl();
  let dataSource = budget[selectedCategory].children;
  dataSource = Object.values(dataSource)
  dataSource = dataSource.sort((a, b) => b.total - a.total);
  const grandTotal = dataSource.reduce((acc, currentValue) => acc + currentValue.total, 0);
  dataSource = dataSource.map((s, i) => ({
    ...s,
    key: i,
    children: null,
    total: formatEuro(s.total),
    grandTotal: grandTotal,
    percentage: parseInt(s.total / grandTotal * 10000, 10) / 100 + '%',
  }))

  const columns = [
    {
      title: () => intl.formatMessage({id: 'home_table_description'}),
      render: (text, item) => <Button type={"a"}
                                      onClick={() => {
                                        onClick(item.category_id)
                                      }}>
        {text}
      </Button>,
      dataIndex: `label_${intl.locale}`,
      key: 'theme',
    },
    {
      title: () => intl.formatMessage(
        defineMessages({id: 'home_table_price'}),
        {x: formatEuro(grandTotal)}
      ),
      dataIndex: 'total',
      key: 'price',
    },
    {
      title: () => intl.formatMessage({id: 'home_table_percent'}),
      dataIndex: 'percentage',
      key: 'percentage',
    },
  ];

  return (
    <div className={TableOverviewStyle.tableWrapper}>
      <Table pagination={{position: 'bottomRight', defaultPageSize: 10, hideOnSinglePage: true}}
             dataSource={dataSource}
             columns={columns}/>
    </div>
  )
}
