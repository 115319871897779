import ArcVizInfoStyle from '../../less/arc-viz-info.module.less';
import {
  FormatNumberToLocaleString,
  FormatNumberToPercentage,
} from '../../helpers/numberFormat';
import { Button } from 'antd';
import React from 'react';
import useIntl from '../../helpers/useIntl';
import PropTypes from 'prop-types';
import { memoize } from 'lodash';
import * as d3 from 'd3';

const darkerColor = memoize((color) => d3.color(color).darker(1.8));

const ArcVizInfo = ({ hoveredItem, totalBudget, color, onClick, onKeyDown }) => {
  color = darkerColor(color);
  const intl = useIntl();

  return (
    <div>
      <div className={ArcVizInfoStyle.titleBlock}>
        <div className={ArcVizInfoStyle.title} style={{ color: color }}>
          {hoveredItem ? intl.translate(hoveredItem, 'label') : ''}
        </div>
      </div>

      <div className={ArcVizInfoStyle.vizHover}>
        <div className={ArcVizInfoStyle.percentage} id="arc-percentage" aria-live={"polite"}>
          {hoveredItem ? FormatNumberToPercentage(hoveredItem.total, totalBudget) + '%' : ''}
        </div>

        <div className="figure">
          {hoveredItem ? '€ ' + FormatNumberToLocaleString(hoveredItem.total) : ''}
        </div>
        <div className={ArcVizInfoStyle.control}>
          {hoveredItem ? (
            <Button
              className={ArcVizInfoStyle.control}
              tabIndex={-1}
              style={{ backgroundColor: color }}
              onKeyDown={e => onKeyDown(e)}
              onClick={e => onClick(hoveredItem.category_id)}>
              {intl.formatMessage({ id: 'general_discover' })}
            </Button>
          ) : ''}
        </div>
      </div>
    </div>
  );
};

ArcVizInfo.propTypes = {
  color: PropTypes.string || null,
};

ArcVizInfo.defaultProps = {
  color: 'green',
};

export default ArcVizInfo;
