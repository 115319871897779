import * as React from 'react';
import {Link} from 'gatsby-plugin-intl';
import QuizStyle from '../../less/quiz-CTA.module.less';
import {useIntl} from 'react-intl';

const QuizCTA = (props) => {
  const intl = useIntl();

  return (
    <div className={QuizStyle.block}>
      <div className={QuizStyle.sideImage}/>
      <div className={QuizStyle.content}>
        <h2 className={'h3'}>{intl.formatMessage({id: 'quiz_CTA_title'})}</h2>
        <p>{intl.formatMessage({id: 'quiz_CTA_explanation'})}</p>
        <Link to={`/quiz/${props.slug}/`} className={'ant-btn ant-btn-quiz'}>
          {intl.formatMessage({id: 'quiz_CTA_buttontext'})}
        </Link>
      </div>
      <div className={QuizStyle.sideImage}/>
    </div>
  );
};

export default QuizCTA;
