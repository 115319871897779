import React from "react"
import DropdownStyle from "../../less/dropdown.module.less"

const YearDropdownMenu = ({years, onSelect}) => {

  return (
    <ul className={DropdownStyle.menu}>
      {years.map((year, index) =>
        (
          <li onClick={() => {
            onSelect(year)
          }} key={index}>
            <a href="#" className={DropdownStyle.item}>
              {year}
            </a>
          </li>
        )
      )}
    </ul>
  )
}

export default YearDropdownMenu
