import {Link} from "gatsby-plugin-intl"
import LeftOutlined from "@ant-design/icons/lib/icons/LeftOutlined"
import React from "react"
import useIntl from "../../helpers/useIntl";

const HomeBackButton = ({trail}) => {
  const intl = useIntl();

  trail = [...trail]

  let append = ""

  if (trail) {
    trail.pop()
    append = trail.join("/")
  }

  return (
    <Link to={`/home/${append ? `${append}/` : ''}`}
          className={'ant-btn ant-btn-link'}>
      <LeftOutlined role={''} aria-label={''}/>
      <span> {intl.formatMessage({ id: 'general_back' })} </span>
    </Link>
  )
}

export default HomeBackButton
