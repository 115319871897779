import React, {useEffect} from "react"
import {navigate} from "@reach/router"
import {Link} from "gatsby-plugin-intl"
import useIntl from "../helpers/useIntl"
import Container from "../components/general/container"
import QuizCTA from "../components/quiz/quiz-CTA"
import NewsCard from "../components/general/news-card"
import ProjectCard from "../components/general/project-card"
import CardGrid from "../components/general/card-grid"
import HomeStyle from "../less/home.module.less"
import SectionStyle from "../less/section.module.less"
import HomeBudget from "../components/home/home-budget"
import HomeLayout from "../components/home/home-layout"
import {makeSearchable} from "../helpers/search"
import {graphql} from 'gatsby'

const IndexPage = props => {
  const intl = useIntl()
  const path = props.location.pathname
  const newsItems = props.data.allNewsItem.edges
  const showcases = props.data.allBudgetShowcase.edges
  const budgetQuiz = props.data.budgetQuiz
  const allBudget = props.data.allBudget

  const [budget, setBudget] = React.useState(null)
  const [searchableBudget, setSearchableBudget] = React.useState(null)

  const MOBILE_WIDTH = 1024

  const trail = props["*"] ? props["*"].split("/") : []

  const categoryIdTrail = trail.slice(2, trail.length).map(
    index => parseInt(index))

  let selectedYear = trail[0] ? trail[0] : null
  let selectedCategory = trail[1] ? trail[1] : null

  let years = allBudget.edges.map(edge => {
    return edge.node.year
  })
  years = [...new Set(years)]

  const [isMobile, setIsMobile] = React.useState(
    typeof window !== `undefined` ? window.innerWidth <= MOBILE_WIDTH : null)

  const isDetailPage = !!(categoryIdTrail.length || (isMobile && selectedCategory))

  let currentBudget = budget || null

  if (selectedCategory) {
    currentBudget = budget ? budget[selectedCategory] : null
  }

  useEffect(() => {
    if (!selectedYear) {
      return
    }

    fetch(`/budgets/budget-${selectedYear}.json`)
      .then(response => response.json()) // parse JSON from request
      .then(resultBudget => {
        setBudget(resultBudget)
        setSearchableBudget(makeSearchable(resultBudget))
      })
  }, [selectedYear])

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobile(window.innerWidth <= MOBILE_WIDTH)
    })

    if (!trail.length) {
      const latestYear = Math.max(...years)
      if (!isMobile) {
        navigate(`${path}${latestYear}/expenses/`, {replace: true})
      } else {
        navigate(`${path}${latestYear}/`, {replace: true})
      }
    }
  }, [isMobile, path, trail])

  for (const budgetItemId of categoryIdTrail) {
    if (currentBudget && currentBudget.children) {
      currentBudget = currentBudget.children[budgetItemId]
    }
  }

  return (
    <HomeLayout
      selectedYear={selectedYear}
      budget={budget}
      isDetailPage={isDetailPage}
      selectedCategory={selectedCategory}
      searchableBudget={searchableBudget}
      currentBudget={currentBudget}
      trail={trail}
      isMobile={isMobile}
      categoryIdTrail={categoryIdTrail}
    >
      {budget ? <HomeBudget
        years={years}
        currentBudget={currentBudget}
        trail={trail}
        categoryIdTrail={categoryIdTrail}
        selectedCategory={selectedCategory}
        selectedYear={selectedYear}
        isMobile={isMobile}
        isDetailPage={isDetailPage}
        path={path}
        budget={budget}
      /> : ""}

      {newsItems.length ? <section>
        <Container size={"small"}>
          <h2
            className={'h3'}>{intl.formatMessage({id: "home_news_title"})}</h2>

          {newsItems.map((newsItem, i) => (
            <div style={{marginBottom: "20px"}} key={i}>
              <NewsCard
                thumbnail={newsItem.node.featuredImg}
                title={intl.translate(newsItem.node, "title")}
                link={newsItem.node.slug}
                description={intl.translate(newsItem.node, "excerpt")}
              />
            </div>
          ))}
        </Container>
      </section> : ""}

      {budgetQuiz ? <section
        className={SectionStyle.isHighlighted + " " + SectionStyle.noPadding}>
        <Container size={"small"}>
          <div className={HomeStyle.ctaBlock}>
            <QuizCTA slug={budgetQuiz.slug}/>
          </div>
        </Container>
      </section> : ""}

      {showcases.length ? <section>
        <Container size={"small"}>
          <h2
            className={'h3'}>{intl.formatMessage({id: "home_projects_title"})}</h2>
          <CardGrid>
            {showcases.map((showcase, i) => (
              <ProjectCard
                key={i}
                thumbnail={showcase.node.featuredImg}
                title={intl.translate(showcase.node, "title")}
                slug={showcase.node.slug}
                description={intl.translate(showcase.node, "excerpt")}
                categories={showcase.node.fields.budgetShowcasePosts}
              />
            ))}
          </CardGrid>
          <div style={{
            textAlign: "right",
            marginTop: "20px",
          }}>
            <Link to={"/showcases"} className={'ant-btn ant-btn-primary'}>
              {intl.formatMessage({id: "projects_all"})}
            </Link>
          </div>
        </Container>
      </section> : ""}
    </HomeLayout>
  )
}

export default IndexPage;

export const query = graphql`
  query {
    allBudget {
      edges {
        node {
          year
        }
      }
    }
    budgetQuiz(active: {eq: true}) {
      slug
    }
    allMenuItem {
      edges {
        node {
          id
          name_nl
          name_fr
          url
          top_menu
          bottom_menu
        }
      }
    }
    allBudgetShowcase(limit: 6) {
      edges {
        node {
          id
          slug
          title_fr
          title_nl
          excerpt_nl
          excerpt_fr
          featuredImg {
            mobile: childImageSharp {
              fluid(maxWidth: 725) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            desktop: childImageSharp {
              fluid(maxWidth: 290) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          fields {
            budgetShowcasePosts {
              fields {
                category {
                  label_nl
                  label_fr
                }
              }
            }
          }
        }
      }
    }
    allNewsItem(sort: {fields: published_at, order: DESC}, limit: 3) {
      edges {
        node {
          id
          slug
          title_fr
          title_nl
          excerpt_nl
          excerpt_fr
          featuredImg {
            mobile: childImageSharp {
              fluid(maxWidth: 725) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            desktop: childImageSharp {
              fluid(maxWidth: 290) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
