function clamp(v, min, max) {
  return v < min ? min : v > max ? max : v;
}

// Calculate a new t based on duration and delay.
function _lerpDelay(t, duration = 1, delay = 0) {
  // Expand t so it fits the duration length.
  t = t / duration;
  if (delay > 0) {
    // Compensate duration for the delay
    delay = delay / duration;
    // Subtract the delay value from "t" to shift it in time.
    // The animation will start playing as soon as t passes the delay threshold.
    t = t - delay;
    // Compress the animation in the remaining time. So if delay = 0.2, t has only 0.8 units
    // of time to interpolate between a and b.
    t = t / (1.0 - delay);
  }
  t = clamp(t, 0.0, 1.0);
  return t;
}

// Linear interpolation function that takes in additional duration / delay.
function lerp(a, b, t, duration = 1, delay = 0) {
  t = _lerpDelay(t, duration, delay);
  return a * (1.0 - t) + b * t;
}

// Cubic interpolation function that takes in additional duration / delay.
function lerpCubic(a, b, t, duration = 1, delay = 0) {
  t = _lerpDelay(t, duration, delay);
  t = ((t *= 2) <= 1 ? t * t * t : (t -= 2) * t * t + 2) / 2;
  return a * (1.0 - t) + b * t;
}

module.exports = { lerp, lerpCubic };
