import HomeOverviewStyle from "../../less/home-overview.module.less"
import button1Unselected from "../../images/button.svg"
import button1Selected from "../../images/button-selected.svg"
import {Button} from "antd"
import button2Selected from "../../images/button-2-selected.svg"
import button2Unselected from "../../images/button-2.svg"
import React, {useEffect, useRef} from "react"
import useIntl from "../../helpers/useIntl"
import FormatNumberToBillions from "../../helpers/numberFormat"
import {motion} from "framer-motion"
import Container from "../general/container";
import TableOverview from "./table-overview";
import ArcVizInfo from "./arc-viz-info";

const HomeOverview = ({
                        budget,
                        onHeightChange,
                        selectedCategory,
                        setSelectedCategory,
                        isMobile,
                        animate,
                        onClick,
                        arcVizInfoKeyboardHandler,
                        hoveredItem,
                        desktopButtonMouseEnterFn,
                        graphType,
                      }) => {
  const intl = useIntl()
  const shouldAnimate = useRef(animate)

  const containerEl = useRef(null)
  let expensesSelected = selectedCategory === "expenses"
  let revenueSelected = selectedCategory === "revenue"

  useEffect(() => {
    setTimeout(() => {
      if (!containerEl || !containerEl.current || typeof window === `undefined`) {
        return;
      }
        onHeightChange(containerEl.current.offsetHeight + 100)
    }, 0)
  }, [budget, onHeightChange])

  return (
    <motion.div
      key="home-overview"
      initial={{opacity: 0}}
      animate={{
        opacity: 1,
        transition: {
          duration: shouldAnimate.current ? 0.3 : 0,
          delay: shouldAnimate.current && graphType === 1 ? 1.3 : 0,
        },
      }}
      exit={{
        opacity: 1,
        transition: {duration: 0},
      }}
      ref={containerEl}>
      <Container size={"small"} noPadding={true}>
        <div className={HomeOverviewStyle.buttonWrapper}
             style={{margin: isMobile ? "0" : "0 20px"}}>
          {isMobile ? (
            <>
              <div
                className={HomeOverviewStyle.button}
                onClick={() => setSelectedCategory("revenue")}
                style={{
                  left: 0,
                  paddingRight: "50px",
                  color: revenueSelected
                    ? "white"
                    : "var(--color-primary)",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: expensesSelected
                    ? `url('${button1Unselected}')`
                    : `url('${button1Selected}')`,
                }}
              >
                <div className={HomeOverviewStyle.buttonText}>
                <h2 className={HomeOverviewStyle.buttonTitle}>
                  {intl.formatMessage({id: "home_buttonrevenue"})}
                </h2>
                  <hr className={HomeOverviewStyle.separator}/>
                  <span
                    className={HomeOverviewStyle.amount}>
                    {FormatNumberToBillions(budget.revenue.total)}
                  </span>
                  <Button
                    type={"primary"}>{intl.formatMessage(
                    {id: "general_discover"})}</Button>
                </div>
              </div>
              <div
                className={HomeOverviewStyle.button}
                onClick={() => setSelectedCategory("expenses")}
                style={{
                  right: 0,
                  color: expensesSelected
                    ? "white"
                    : "var(--color-primary)",
                  paddingLeft: "50px",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: expensesSelected
                    ? `url('${button2Selected}')`
                    : `url('${button2Unselected}')`,
                }}
              >
                <div className={HomeOverviewStyle.buttonText}>
                <h2 className={HomeOverviewStyle.buttonTitle}>
                  {intl.formatMessage({id: "home_buttonexpense"})}
                </h2>
                  <hr className={HomeOverviewStyle.separator}/>
                  <span
                    className={HomeOverviewStyle.amount}>{FormatNumberToBillions(
                    budget.expenses.total)}</span>
                  <Button
                    type={"primary"}>{intl.formatMessage(
                    {id: "general_discover"})}</Button>
                </div>
              </div>
            </>
          ) : ""}

          {!isMobile && graphType === 1 ? (
            <>
              {/*REVENUE */}
              <div
                onMouseEnter={desktopButtonMouseEnterFn}
                className={HomeOverviewStyle.button}
                onClick={() => setSelectedCategory("revenue")}
                style={{
                  left: 0,
                  cursor: "pointer",
                  backgroundRepeat: "no-repeat",
                  paddingRight: "50px",
                  color: revenueSelected
                    ? "white"
                    : "var(--color-primary)",
                  backgroundImage: expensesSelected
                    ? `url('${button1Unselected}')`
                    : `url('${button1Selected}')`,
                }}>
                <div className={HomeOverviewStyle.buttonText}>
                  <h2 className={HomeOverviewStyle.buttonTitle}
                      style={{
                        color: revenueSelected
                          ? "white"
                          : "var(--color-primary)",
                      }}>
                    {intl.formatMessage({id: "home_buttonrevenue"})}
                  </h2>
                  <hr className={HomeOverviewStyle.separator}/>
                  <span
                    className={HomeOverviewStyle.amount}>{FormatNumberToBillions(
                    budget.revenue.total)}</span>
                  {expensesSelected || isMobile ? (
                    <Button type={"primary"} size={"small"}
                            className={HomeOverviewStyle.fauxButton}>
                      {intl.formatMessage({id: "home_view"})}
                    </Button>
                  ) : <span
                    className={HomeOverviewStyle.activeButtonInfo}>{intl.formatMessage(
                    {id: "home_clickoncircle"})}</span>}
                </div>
              </div>
              {/* expenses */}
              <div
                className={HomeOverviewStyle.button}
                onMouseEnter={desktopButtonMouseEnterFn}
                onClick={() => setSelectedCategory("expenses")}
                style={{
                  right: 0,
                  cursor: "pointer",
                  backgroundRepeat: "no-repeat",
                  color: expensesSelected
                    ? "white"
                    : "var(--color-primary)",
                  paddingLeft: "50px",
                  backgroundImage: expensesSelected
                    ? `url('${button2Selected}')`
                    : `url('${button2Unselected}')`,
                }}
              >
                <div className={HomeOverviewStyle.buttonText}>
                  <h2 className={HomeOverviewStyle.buttonTitle}
                      style={{
                        color: expensesSelected
                          ? "white"
                          : "var(--color-primary)",
                      }}>
                    {intl.formatMessage({id: "home_buttonexpense"})}
                  </h2>
                  <hr className={HomeOverviewStyle.separator}/>
                  <span
                    className={HomeOverviewStyle.amount}>{FormatNumberToBillions(
                    budget.expenses.total)}</span>
                  {revenueSelected || isMobile ? (
                    <Button type={"primary"} size={"small"}
                            className={HomeOverviewStyle.fauxButton}>
                      {intl.formatMessage({id: "home_view"})}
                    </Button>
                  ) : <span
                    className={HomeOverviewStyle.activeButtonInfo}>{intl.formatMessage(
                    {id: "home_clickoncircle"})}</span>}
                </div>
              </div>
            </>
          ) : ''}
        </div>
      </Container>

      {!isMobile && typeof window !== `undefined` && graphType === 1 && (
        <ArcVizInfo onClick={(category) => onClick(category)}
                    onKeyDown={(e) => arcVizInfoKeyboardHandler(e)}
                    color={hoveredItem?.color}
                    hoveredItem={hoveredItem}
                    totalBudget={budget[selectedCategory]?.total}/>
      )}

      {!isMobile && graphType === 2 && (
        <TableOverview isMobile={isMobile}
                       budget={budget}
                       onClick={(category) => onClick(category)}
                       selectedCategory={selectedCategory}/>
      )}

    </motion.div>
  )
}

export default HomeOverview
