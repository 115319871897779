import React, { Fragment, useEffect, useRef, useState } from 'react';
import useIntl from '../../helpers/useIntl';
import * as d3 from 'd3';
import { memoize } from 'lodash';

import { lerp, lerpCubic } from '../../helpers/animation';

const PI = Math.PI;
const HALF_PI = Math.PI / 2;
const TWO_PI = Math.PI * 2;

const gSettings = {
  // totalAngleDegrees: 90,
  desiredAngleDegrees: 100,
  zoomedRadius: 220,
  zoomedThickness: 30,
  zoomInfluence: 2.5,
  selectedSegmentIndex: 0,
  duration: 1000,
  tx: 0,
  zoom: true,
  zoomFactor: 4.0,
};

function toRadians(deg) {
  return deg * (Math.PI / 180);
}

const darkenColor = memoize((color) => d3.color(color).darker(1.8));

function measureContext() {
  let _minX = Infinity;
  let _minY = Infinity;
  let _maxX = -Infinity;
  let _maxY = -Infinity;

  function moveTo(x, y) {
    _minX = Math.min(_minX, x);
    _minY = Math.min(_minY, y);
    _maxX = Math.max(_minX, x);
    _maxY = Math.max(_maxY, y);
  }

  function lineTo(x, y) {
    _minX = Math.min(_minX, x);
    _minY = Math.min(_minY, y);
    _maxX = Math.max(_minX, x);
    _maxY = Math.max(_maxY, y);
  }

  function getQuadrant(_angle) {
    const angle = _angle % TWO_PI;
    if (angle > 0.0 && angle < HALF_PI) return 0;
    if (angle >= HALF_PI && angle < PI) return 1;
    if (angle >= PI && angle < PI + HALF_PI) return 2;
    return 3;
  }

  function arc(tx, ty, r, a0, a1, ccw) {
    if (ccw) {
      [a0, a1] = [a1, a0];
    }
    const a0Quad = getQuadrant(a0);
    const a1Quad = getQuadrant(a1);

    const ix = Math.cos(a0) * r;
    const iy = Math.sin(a0) * r;
    const ex = Math.cos(a1) * r;
    const ey = Math.sin(a1) * r;

    const minX = Math.min(ix, ex);
    const minY = Math.min(iy, ey);
    const maxX = Math.max(ix, ex);
    const maxY = Math.max(iy, ey);

    // const r = radius;
    const xMax = [
      [maxX, r, r, r],
      [maxX, maxX, r, r],
      [maxX, maxX, maxX, r],
      [maxX, maxX, maxX, maxX],
    ];
    const yMax = [
      [maxY, maxY, maxY, maxY],
      [r, maxY, r, r],
      [r, maxY, maxY, r],
      [r, maxY, maxY, maxY],
    ];
    const xMin = [
      [minX, -r, minX, minX],
      [minX, minX, minX, minX],
      [-r, -r, minX, -r],
      [-r, -r, minX, minX],
    ];
    const yMin = [
      [minY, -r, -r, minY],
      [minY, minY, -r, minY],
      [minY, minY, minY, minY],
      [-r, -r, -r, minY],
    ];

    const x1 = xMin[a1Quad][a0Quad];
    const y1 = yMin[a1Quad][a0Quad];
    const x2 = xMax[a1Quad][a0Quad];
    const y2 = yMax[a1Quad][a0Quad];

    _minX = Math.min(_minX, tx + x1);
    _minY = Math.min(_minY, ty + y1);
    _maxX = Math.max(_maxX, tx + x2);
    _maxY = Math.max(_maxY, ty + y2);
  }

  function closePath() {}

  return {
    moveTo,
    lineTo,
    arc,
    closePath,
    minX: () => _minX,
    minY: () => _minY,
    maxX: () => _maxX,
    maxY: () => _maxY,
  };
}

let gMemoizedBounds = {};

function measureArcBounds(segment, innerRadius, outerRadius) {
  console.assert(segment);
  if (
    gMemoizedBounds.startAngle === segment.startAngle &&
    gMemoizedBounds.endAngle === segment.endAngle &&
    gMemoizedBounds.padAngle === segment.padAngle &&
    gMemoizedBounds.innerRadius === innerRadius &&
    gMemoizedBounds.outerRadius === outerRadius
  ) {
    return gMemoizedBounds.bounds;
  }
  let ctx = measureContext();
  const arcMeasurer = d3
    .arc()
    .innerRadius(innerRadius)
    .outerRadius(outerRadius)
    .context(ctx);
  // We need at least a minimal start / end angle, otherwise the arc will zoom in too much.
  // This is just for measuring the bounds, not for the actual drawing.
  let { startAngle, endAngle } = segment;
  if (endAngle - startAngle < 0.01) {
    startAngle -= 0.1;
    endAngle += 0.1;
  }
  arcMeasurer({
    startAngle: startAngle,
    endAngle: endAngle,
    padAngle: segment.padAngle,
  });
  const bounds = {
    x: ctx.minX(),
    y: ctx.minY(),
    width: ctx.maxX() - ctx.minX(),
    height: ctx.maxY() - ctx.minY(),
  };
  gMemoizedBounds = {
    startAngle: segment.startAngle,
    endAngle: segment.endAngle,
    padAngle: segment.padAngle,
    innerRadius: innerRadius,
    outerRadius: outerRadius,
    bounds,
  };
  return bounds;
}

function calculateSegments(item, startAngle, endAngle, anglePadding) {
  return d3
    .pie()
    .value((d) => d.total / item.total)
    .startAngle(startAngle)
    .endAngle(endAngle)
    .padAngle(anglePadding)(Object.values(item.children));
}

function calculateOuterAngles(outerSegment, originalOuterSegment) {
  // Calculate angle scale as a fraction of the old angle to the desired angle.
  let oldAngle = outerSegment.endAngle - outerSegment.startAngle;
  let desiredAngle = toRadians(gSettings.desiredAngleDegrees);
  let angleScale = desiredAngle / oldAngle;

  // Based on the angle scale, calculate the total angle of all segments.
  // This can never be larger than a full circle (PI * 2).
  let totalAngle = desiredAngle * angleScale;
  totalAngle = Math.min(totalAngle, TWO_PI);

  // Calculate the position of the middle of our segment on a full circle (0.0 - 1.0).
  let midPos =
    lerp(outerSegment.startAngle, outerSegment.endAngle, 0.5) / (Math.PI * 2);
  // This is the final rotation of the circle (PI / 2 ==> 90 degrees)
  let newMiddleAngle = Math.PI / 2;
  // Calculate how far off we are from the middle of our segment to the desired position.
  let angleDelta = midPos * totalAngle;
  let startAngle = newMiddleAngle - angleDelta;
  let endAngle = newMiddleAngle + totalAngle - angleDelta;
  // If the turn is too big, rotate in the other direction.
  if (originalOuterSegment.startAngle > Math.PI * 1.5) {
    startAngle += Math.PI * 2;
    endAngle += Math.PI * 2;
  }
  return [startAngle, endAngle];
}

function calculateOuterSegments(budget, activeSegment, originalOuterSegment) {
  if (!activeSegment) {
    let d1 = d3
      .pie()
      .value((d) => d.total / budget.total)
      .startAngle(0)
      .endAngle(TWO_PI)
      .padAngle(0.04)(Object.values(budget.children));

    return d1;
  } else {
    const [outerStartAngle, outerEndAngle] = calculateOuterAngles(
      activeSegment,
      originalOuterSegment
    );
    // Calculate the new angles of the arc when zoomed.
    const pie = d3
      .pie()
      .value((d) => d.total / budget.total)
      .startAngle(outerStartAngle)
      .endAngle(outerEndAngle)
      .padAngle(0.002);
    return pie(Object.values(budget.children));
  }
}

function calculateTransform(bounds, t, width) {
  let dstX = -(width / 2) + 40;
  let dstY = 0;
  dstX = lerpCubic(0.0, dstX, t, 1.0, 0.0);
  dstY = lerpCubic(0.0, dstY, t, 1.0, 0.0);
  let dstWidth = 300;
  let dstHeight = 300;
  const sx = dstWidth / bounds.width;
  const sy = dstHeight / bounds.height;
  let scale = Math.min(sx, sy);
  scale = lerpCubic(1.0, scale, t, 1.0, 0.1);
  let tx = -bounds.width / 2 - bounds.x;
  let ty = -bounds.height / 2 - bounds.y;
  tx = lerpCubic(0.0, tx, t, 1.0, 0.0);
  ty = lerpCubic(0.0, ty, t, 1.0, 0.0);

  return `translate(${dstX}, ${dstY}) scale(${scale}, ${scale}) translate(${tx}, ${ty})`;
}

function calculateDoubleTransform(srcBounds, dstBounds, t, width) {
  let dstX = -(width / 2) + 40;
  const dstY = 0;
  const dstWidth = 300;
  const dstHeight = 300;
  const srcScaleX = dstWidth / srcBounds.width;
  const srcScaleY = dstHeight / srcBounds.height;
  const dstScaleX = dstWidth / dstBounds.width;
  const dstScaleY = dstHeight / dstBounds.height;
  const srcScale = Math.min(srcScaleX, srcScaleY);
  const dstScale = Math.min(dstScaleX, dstScaleY);
  const scale = lerpCubic(srcScale, dstScale, t, 1.0, 0.1);
  const srcTx = -srcBounds.width / 2 - srcBounds.x;
  const srcTy = -srcBounds.height / 2 - srcBounds.y;
  const dstTx = -dstBounds.width / 2 - dstBounds.x;
  const dstTy = -dstBounds.height / 2 - dstBounds.y;
  const tx = lerpCubic(srcTx, dstTx, t, 1.0, 0.0);
  const ty = lerpCubic(srcTy, dstTy, t, 1.0, 0.0);

  return `translate(${dstX}, ${dstY}) scale(${scale}, ${scale}) translate(${tx}, ${ty})`;
}

function calculateOuterArcs(
  innerRadius,
  outerRadius,
  startTrail,
  endTrail,
  t = 0
) {
  const startZoomedOut = startTrail.length === 0;
  const endZoomedOut = endTrail.length === 0;

  let newInnerRadius;
  let cornerRadius;
  if (startZoomedOut && endZoomedOut) {
    return d3
      .arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius)
      .cornerRadius(3);
  } else if (startZoomedOut) {
    newInnerRadius = lerpCubic(
      lerp(innerRadius, outerRadius, 0.5),
      innerRadius,
      t,
      0.2,
      0.8
    );
    cornerRadius = lerpCubic(1, 3, t, 0.2, 0.8);
  } else if (endZoomedOut) {
    newInnerRadius = lerpCubic(
      innerRadius,
      lerp(innerRadius, outerRadius, 0.5),
      t,
      0.2,
      0.8
    );
    cornerRadius = lerpCubic(3, 1, t, 0.2, 0.8);
  } else {
    newInnerRadius = lerp(innerRadius, outerRadius, 0.5);
    cornerRadius = 1;
  }

  return d3
    .arc()
    .innerRadius(newInnerRadius)
    .outerRadius(outerRadius)
    .cornerRadius(cornerRadius);
}

function calculateSegmentList(budget, largestTrail, activeTrail) {
  // Pre-calculate the "active segment": the start / end angle of the deepest level of the trail.
  // We need these angles to rotate and scale our final arc.
  let activeStartAngle = 0;
  let activeEndAngle = TWO_PI;

  // Calculate the start / end angles of the original outer segments.
  // This is used to know in which direction we need to turn (clockwise or counterclockwise).
  const fractions = d3.pie().value((d) => d.total / budget.total)(
    Object.values(budget.children)
  );
  const originalOuterSegment = fractions.find(
    (segment) => segment.data.category_id === activeTrail[0]
  );

  {
    let budgetItem = budget;
    for (let budgetItemId of activeTrail) {
      // Trails are stored as numbers, but budget item keys are strings.
      budgetItemId = budgetItemId.toString();
      const childBudgetItem = budgetItem.children[budgetItemId];
      console.assert(
        childBudgetItem,
        `Could not find ${budgetItemId} in ${JSON.stringify(
          budgetItem.children
        )}`
      );
      const budgetItemIndex = Object.keys(budgetItem.children).indexOf(
        budgetItemId
      );
      console.assert(
        budgetItemIndex !== -1,
        `Could not find ${budgetItemId} in ${JSON.stringify(
          budgetItem.children
        )}`
      );

      const pie = d3
        .pie()
        .value((d) => d.total / budget.total)
        .startAngle(activeStartAngle)
        .endAngle(activeEndAngle)
        .padAngle(0); // FIXME set up the padAngle.

      const segments = pie(Object.values(budgetItem.children));

      const activeSegment = segments[budgetItemIndex];
      console.assert(activeSegment, `No active segment found`);

      activeStartAngle = activeSegment.startAngle;
      activeEndAngle = activeSegment.endAngle;

      budgetItem = childBudgetItem;
    }
  }

  // Calculate the segments, hierarchically, for each part of the trail.
  let segmentList = [];

  {
    let padAngle = 0.005;
    let parentItem = budget;

    let activeSegment;
    if (!activeTrail.length) {
      activeSegment = null;
    } else {
      // we need to find the "position" of this budget item in the total.
      activeSegment = {
        startAngle: activeStartAngle,
        endAngle: activeEndAngle,
      };
    }

    // Outer layer is not part of trail
    let parentSegments = calculateOuterSegments(
      parentItem,
      activeSegment,
      originalOuterSegment
    );

    segmentList.push(parentSegments);

    for (const budgetItemId of largestTrail) {
      parentItem = parentItem.children[budgetItemId];

      let childSegment = parentSegments.find(
        (parentSegment) => parentSegment.data.category_id === budgetItemId
      );
      if (!childSegment) {
        throw new Error(
          `Could not find item ${budgetItemId} in parent item ${parentItem}. Trail: ${largestTrail}.`
        );
      }

      let startAngle = childSegment.startAngle;
      let endAngle = childSegment.endAngle;

      parentSegments = calculateSegments(
        parentItem,
        startAngle,
        endAngle,
        padAngle
      );

      segmentList.push(parentSegments);
    }
  }

  return segmentList;
}

function findSegmentByTrail(segmentList, trail) {
  // We don't have to traverse the entire segment list, just retrieve the last part of the trail.
  const lastId = trail[trail.length - 1];
  const nextToLastSegment = segmentList[trail.length - 1];
  const result = nextToLastSegment.find(
    (segment) => segment.data.category_id === lastId
  );
  console.assert(result, `Could not find ${trail} in ${segmentList}.`);
  return result;
}

function ArcViz({
  budget,
  budgetCategory,
  trail,
  hoveredItem,
  onClickItem,
  onGoBack,
  onItemEnter,
  onItemLeave,
  onAnimationFinished,
  onSegmentFocus,
}) {
  const intl = useIntl();
  const [t, setT] = useState(0);
  const frameRef = useRef();
  const startTimeRef = useRef();
  const tOffsetRef = useRef(0);
  const [startTrail, setStartTrail] = useState(null);
  const [endTrail, setEndTrail] = useState(null);
  const prevTailRef = useRef();
  const [hoverSegment, setHoverSegment] = useState(null);

  const startSegmentListRef = useRef();
  const endSegmentListRef = useRef();

  const [svgWidth, setSvgWidth] = useState(1180);
  const svgHeight = trail.length > 0 ? 600 : 430;
  const svgSmallestHeight = 430;

  const svgRef = useRef();
  const debug = false;

  const updateGraph = () => {
    // Deep link
    if (startTrail === null) {
      setStartTrail(trail);
      setEndTrail(trail);
      return;
    }

    // Check if trails are completely different. This happens when using the search field.
    // This will fix the trail to a certain setting.
    const smallestTrailLength = Math.min(startTrail.length, trail.length);
    let trailMatches = true;
    for (let i = 0; i < smallestTrailLength; i++) {
      if (startTrail[i] !== trail[i]) {
        trailMatches = false;
        break;
      }
    }

    // console.log(trail, startTrail, endTrail);
    // If the trail has changed, cancel the current animation frame.
    if (frameRef.current) {
      window.cancelAnimationFrame(frameRef.current);
      frameRef.current = null;
      tOffsetRef.current = t;
      startTimeRef.current = 0;
    }

    setEndTrail(trail);

    // Cache segment lists
    if (trailMatches) {
      const largestTrail = startTrail.length > trail.length ? startTrail : trail;
      startSegmentListRef.current = calculateSegmentList(budget, largestTrail, startTrail);
      endSegmentListRef.current = calculateSegmentList(budget, largestTrail, trail);
    } else {
      setStartTrail(trail);
      startSegmentListRef.current = calculateSegmentList(budget, trail, trail);
      endSegmentListRef.current = calculateSegmentList(budget, trail, trail);
      setT(1);
      return;
    }
    const duration = trail.length > 0 && startTrail.length > 0 ? 750 : 1500;

    const onFrame = (timestamp) => {
      if (!startTimeRef.current) startTimeRef.current = timestamp;
      let elapsed = timestamp - startTimeRef.current;
      // Add some extra time if the trail has changed while the animation is playing.
      let t = elapsed / duration;
      t *= 1.0 - tOffsetRef.current;
      t += tOffsetRef.current;
      if (t < 1.0) {
        setT(t);
        frameRef.current = window.requestAnimationFrame(onFrame);
      } else {
        setStartTrail(trail);
        const segmentList = calculateSegmentList(budget, trail, trail);
        startSegmentListRef.current = segmentList;
        endSegmentListRef.current = segmentList;
        setT(0);
        onAnimationFinished && onAnimationFinished();
        startTimeRef.current = 0;
        tOffsetRef.current = 0;
      }
    };

    frameRef.current = window.requestAnimationFrame(onFrame);
  };

  useEffect(() => {
    setStartTrail(trail);
    setEndTrail(trail);
    const segmentList = calculateSegmentList(budget, trail, trail);
    startSegmentListRef.current = segmentList;
    endSegmentListRef.current = segmentList;
    if (frameRef.current) {
      window.cancelAnimationFrame(frameRef.current);
    }
    tOffsetRef.current = 0;
    startTimeRef.current = 0;
    setT(0);
  }, [budget]);

  useEffect(() => {
    // I need to use requestAnimationFrame, otherwise svgRef.current is not available.
    window.requestAnimationFrame(() => {
      if (svgRef.current) {
        const graphScale = [
          { transform: 'scale(0)' },
          { transform: 'scale(1)' },
        ];
        const graphTiming = {
          duration: 500,
          iterations: 1,
          easing: 'ease-out',
        };
        svgRef.current.animate(graphScale, graphTiming);
      }
    });
  }, [budgetCategory]);

  useEffect(() => {
    if (JSON.stringify(prevTailRef.current) === JSON.stringify(trail)) return;
    prevTailRef.current = JSON.parse(JSON.stringify(trail));
    updateGraph();
  }, [trail]);

  const onResize = () => {
    if (svgRef.current) {
      const bounds = svgRef.current.parentElement.getBoundingClientRect();
      setSvgWidth(bounds.width);
    } else {
      setSvgWidth(1180);
    }
  };

  useEffect(() => {
    const listener = window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', listener);
  }, []);
  window.requestAnimationFrame(onResize);

  if (startTrail === null || endTrail === null) return null;

  let animateOuter = false;
  if (startTrail.length === 0 && endTrail.length === 1) animateOuter = true;
  if (startTrail.length === 1 && endTrail.length === 0) animateOuter = true;
  let outerT = 1.0;
  if (animateOuter) {
    if (startTrail.length <= endTrail.length) {
      outerT = t;
    } else {
      outerT = 1.0 - t;
    }
  }

  let rings = [];

  const largestTrail =
    startTrail.length > endTrail.length ? startTrail : endTrail;
  // let startSegmentList = calculateSegmentList(budget, largestTrail, startTrail);
  // let endSegmentList = calculateSegmentList(budget, largestTrail, endTrail);

  // console.assert(startSegmentList.length === endSegmentList.length);
  const startSegmentList = startSegmentListRef.current;
  const endSegmentList = endSegmentListRef.current;
  let interpolatedSegmentList = [];
  for (let i = 0; i < startSegmentList.length; i++) {
    const startSegments = startSegmentList[i];
    const endSegments = endSegmentList[i];
    const interpolatedSegments = [];
    // console.assert(startSegments.length === endSegments.length);
    for (let j = 0; j < startSegments.length; j++) {
      const startSegment = startSegments[j];
      const endSegment = endSegments[j];
      // prettier-ignore
      interpolatedSegments.push({
        startAngle: lerpCubic(startSegment.startAngle, endSegment.startAngle, t,
          1.0, 0.0),
        endAngle: lerpCubic(startSegment.endAngle, endSegment.endAngle, t, 1.0,
          0.0),
        padAngle: lerpCubic(startSegment.padAngle, endSegment.padAngle, t, 1.0,
          0.0),
        data: startSegment.data
      })
    }
    interpolatedSegmentList.push(interpolatedSegments);
  }

  let activeSegment;

  if (endTrail.length) {
    activeSegment = findSegmentByTrail(endSegmentList, endTrail);
  } else {
    activeSegment = null;
  }

  // Calculate outer segments
  let innerRadius = 100;
  let outerRadius = 120;

  // If we're animating, calculate where we're supposed to land.
  let transform;
  let endBounds;

  if (startTrail.length > 0 && endTrail.length > 0) {
    endBounds = measureArcBounds(activeSegment, innerRadius, outerRadius);
    const startSegment = findSegmentByTrail(startSegmentList, startTrail);
    const startBounds = measureArcBounds(
      startSegment,
      innerRadius,
      outerRadius
    );
    transform = calculateDoubleTransform(startBounds, endBounds, t, svgWidth);
  } else if (endTrail.length > 0) {
    endBounds = measureArcBounds(activeSegment, innerRadius, outerRadius);
    transform = calculateTransform(endBounds, t, svgWidth);
  } else if (startTrail.length > 0) {
    const startSegment = findSegmentByTrail(startSegmentList, startTrail);
    endBounds = measureArcBounds(startSegment, innerRadius, outerRadius);
    transform = calculateTransform(endBounds, 1 - t, svgWidth);
  } else {
    transform = '';
    endBounds = {
      x: 0,
      y: 0,
      w: 0,
      h: 0,
    };
  }

  const arcGenerator = calculateOuterArcs(
    innerRadius,
    outerRadius,
    startTrail,
    endTrail,
    t
  );

  const pieGenerator = calculateOuterArcs(
    innerRadius - 20,
    outerRadius + 20,
    startTrail,
    endTrail,
    t
  );

  let ringsTransform = transform;

  const isAnimating = t !== 0;
  const isInteractive = startTrail.length === endTrail.length;
  const isOuterInteractive = isInteractive && startTrail.length === 0;

  const _onItemEnter = (segment) => {
    setHoverSegment(segment);
    onItemEnter(segment.data);
  };

  const _onItemLeave = (segment) => {
    setHoverSegment(null);
    onItemLeave(segment.data);
  };

  // The outer segments actually consist of a visible arc, and an invisible pie that provides the click target.
  const outerSegments = interpolatedSegmentList[0];
  const arcs = outerSegments.map((segment, i) => {
    return (
      <Fragment key={i}>
        {isOuterInteractive ? (
          <a
            href={`./${segment.data.category_id}/`}
            aria-label={segment.data[`label_${intl.locale}`]}
            aria-controls={'arc-percentage'}
            tabIndex={0}
            onFocus={onSegmentFocus.bind(this, segment.data)}
            onClick={(e) => {
              e.preventDefault();
              setHoverSegment(null);
              onClickItem(segment.data.category_id);
            }}
          >
            <path
              style={{ cursor: isOuterInteractive ? 'pointer' : 'auto' }}
              key="pie"
              d={pieGenerator(segment)}
              fill="transparent"
              onMouseEnter={() => isOuterInteractive && _onItemEnter(segment)}
              onMouseLeave={() => isOuterInteractive && _onItemLeave(segment)}
            />
          </a>
        ) : null}
        <path
          style={{
            pointerEvents: 'none',
            transition: isAnimating ? 'none' : 'transform,stroke-width 0.3s',
            paintOrder: 'stroke',
          }}
          key="arc"
          d={arcGenerator(segment)}
          transform={
            hoverSegment &&
            segment.data.category_id === hoverSegment.data.category_id
              ? 'scale(1.05)'
              : ''
          }
          fill={segment.data.color}
          stroke={darkenColor(segment.data.color)}
          strokeWidth={isOuterInteractive ? 3.5 : 0}
        />
      </Fragment>
    );
  });
  rings.push(<g key="root">{arcs}</g>);

  // Calculate inner segments
  innerRadius += 11;
  outerRadius -= 1;

  if (largestTrail.length > 0 && outerT > 0.6) {
    for (let i = 1; i < interpolatedSegmentList.length; i++) {
      const segments = interpolatedSegmentList[i];
      // Only the inner ring is interactive
      const isRingInteractive = i === interpolatedSegmentList.length - 1;

      const arcGenerator = d3
        .arc()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius)
        .cornerRadius(1);

      const arcs = segments.map((segment, i) => {
        const children = Object.keys(segment.data.children);
        const deepestSegment = children.length === 0;
        return (
          <a
            key={i}
            aria-label={segment.data[`label_${intl.locale}`]}
            tabIndex={-1}
            href={`./${segment.data.category_id}/`}
            onClick={(e) => {
              e.preventDefault();
              !deepestSegment && onClickItem(segment.data.category_id);
            }}
          >
            <path
              d={arcGenerator(segment)}
              fill={segment.data.color}
              stroke={
                t === 0.0 && hoveredItem === segment.data
                  ? 'rgba(255, 255, 255, 0.8)'
                  : 'none'
              }
              strokeWidth={0.5}
              style={{
                cursor:
                  isRingInteractive && !deepestSegment ? 'pointer' : 'auto',
                transition: isAnimating ? 'none' : 'transform 0.3s',
              }}
              onMouseEnter={() => isRingInteractive && _onItemEnter(segment)}
              onMouseLeave={() => isRingInteractive && _onItemLeave(segment)}
            />
          </a>
        );
      });
      rings.push(
        <g key={i} className="fade-in">
          {arcs}
        </g>
      );

      innerRadius += 1;
      outerRadius -= 1;
    }
  }

  if (debug) {
    rings.push(
      <rect
        x={endBounds.x}
        y={endBounds.y}
        width={endBounds.width}
        height={endBounds.height}
        stroke="red"
        fill="none"
        opacity={t}
      />
    );
  }

  if (rings.length > 1) {
    rings.push(
      <circle
        key="back"
        cx={0}
        cy={0}
        r={innerRadius - 4}
        fill="transparent"
        style={{ cursor: 'pointer' }}
        onClick={onGoBack}
      />
    );
  }

  return (
    <svg width={svgWidth} height={svgHeight} ref={svgRef}>
      <g transform={`translate(${svgWidth / 2}, ${svgSmallestHeight / 2})`}>
        <g transform={ringsTransform}>{rings}</g>
      </g>

      {debug && (
        <g>
          <line
            x1={svgWidth / 2}
            y1={50}
            x2={svgWidth / 2 + t * 100}
            y2={50}
            stroke="red"
            strokeWidth={5}
          />
          <line
            x1={svgWidth / 2}
            y1={40}
            x2={svgWidth / 2}
            y2={60}
            stroke="red"
            strokeWidth={1}
          />
          <line
            x1={svgWidth / 2 + 100}
            y1={40}
            x2={svgWidth / 2 + 100}
            y2={60}
            stroke="red"
            strokeWidth={1}
          />
        </g>
      )}
    </svg>
  );
}

export default ArcViz;
