import React, {useEffect, useRef, useState} from "react"
import {Location, navigate, Router} from "@reach/router"
import SectionStyle from "../../less/section.module.less"
import HomeStyle from "../../less/home.module.less"
import HomeBackButton from "./back-button"
import HomeOverview from "./home-overview"
import HomeDetail from "./home-detail"
import ArcViz from "./arc-viz"
import BarViz from "./bar-viz"
import Controls from "./controls"
import {AnimatePresence} from "framer-motion"
import {useIntl} from "react-intl";

const FramerRouter = ({children}) => (
  <Location>
    {({location}) => (
      <div style={{position: "relative"}}>
        <AnimatePresence>
          <Router basepath="/:lang/home/" primary={false} key={location.key}
                  location={location}>
            {children}
          </Router>
        </AnimatePresence>
      </div>
    )}
  </Location>
)

const HomeBudget = props => {
  let {
    trail,
    budget,
    currentBudget,
    selectedCategory,
    categoryIdTrail,
    isMobile,
    isDetailPage,
    path,
    selectedYear,
    years
  } = props
  let offSet

  const [graphType, setGraphType] = React.useState(1);

  function onChangeGraphType(e) {
    setGraphType(e.target.value);
  }

  const vizContainer = useRef(null)
  const graphContainerRef = useRef(null)
  const KEY_TAB = 9

  const [budgetListHeight, setBudgetListHeight] = useState(null)
  const [hoveredItem, setHoveredItem] = useState(null)
  const [hoverItemIndex, setHoverItemIndex] = useState(0)
  const [wasDetailPage, setWasDetailPage] = useState(null)
  const intl = useIntl();

  const onClick = id => {
    if (currentBudget.children && currentBudget.children[id] && Object.keys(
      currentBudget.children[id].children).length) {
      goTo(`${path}${id}/`, graphContainerRef)
    } else {
      console.error("budget category not found", `${path}${id}`)
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWasDetailPage(isDetailPage)
    }
  })


  const getBudgetListMinHeight = () => {
    if (isDetailPage) {
      if (isMobile) {
        return "660px"
      }

      return "400px"
    }

    if (isMobile) {
      return "340px"
    }

    return "400px"
  }

  const goTo = async (newPath, scrollToRef) => {
    offSet = window.pageYOffset

    await navigate(newPath)

    return //temp remove scroll to behavior

    window.scrollTo(0, offSet)

    if (scrollToRef) {
      scrollToRef.current.scrollIntoView({behavior: "auto"})
    }
  }

  let budgetListMinHeight = 0

  budgetListMinHeight = getBudgetListMinHeight()

  selectedCategory = selectedCategory || "expenses"

  function homeOverviewKeyboardHandler(e) {
    // This selects the first item in the graph when
    // the user presses TAB while the faux buttons have focus (HomeOverview).
    if (e.which === KEY_TAB) {
      const categoryItems = budget[selectedCategory].children
      const keys = Object.keys(categoryItems)

      if (e.shiftKey) {
        return
      }

      setHoveredItem(categoryItems[keys[0]])
      setHoverItemIndex(0)
    }
  }

  async function arcVizInfoKeyboardHandler(e) {
    // This puts the focus on the next item in the graph when
    // the user presses TAB while the select button of ArcVizInfo has the focus.
    // Press SHIFT + TAB to leave this keyboard trap

    if (e.which === KEY_TAB) {
      const categoryItems = budget[selectedCategory].children
      const categoryItemKeys = Object.keys(categoryItems)
      setHoverItemIndex(hoverItemIndex + 1)
      setHoveredItem(categoryItems[categoryItemKeys[hoverItemIndex]])

      if (hoverItemIndex <= categoryItemKeys.length - 1 && !e.shiftKey) {
        e.preventDefault()
      } else {
        setHoverItemIndex(0)
      }
    }
  }

  function goBack() {
    const parts = document.location.pathname.split('/');
    if (parts[parts.length - 1] === '') {
      parts.pop();
    }
    parts.pop();
    let newPath = parts.join("/");
    newPath += '/';
    navigate(newPath);
  }

  function onMouseOutFn(elem) {
    setHoveredItem(null)
    setHoverItemIndex(null)
  }

  return <section
    style={{
      height: `${budgetListHeight}px`,
      minHeight: budgetListMinHeight,
      zIndex: '100'
    }}
    className={SectionStyle.isGrayHighlighted + " " + SectionStyle.noPadding}>
    <div ref={graphContainerRef} className={HomeStyle.container} style={{
      padding: (isDetailPage || !isMobile) ? "0" : "40px 20px",
    }}>
      {isDetailPage ? (
        <div className={`${HomeStyle.header} ${HomeStyle.detailPageHeader}`}>
          <div className={HomeStyle.left}>
            <HomeBackButton trail={trail}/>
          </div>
          <span className={HomeStyle.center}/>
        </div>
      ) : (
        <div className={`${HomeStyle.header}`}>
          <div className={HomeStyle.fixed}>
            <Controls isMobile={isMobile}
                      years={years}
                      selectedYear={selectedYear}
                      setSelectedCategory={(category) => goTo(`../${category}/`)}
                      selectedCategory={selectedCategory}
                      onChangeGraphType={onChangeGraphType}
                      selectedGraphType={graphType}/>
          </div>
        </div>
      )}

      <div onKeyDown={e => homeOverviewKeyboardHandler(e)}>
        <FramerRouter>
          <HomeOverview
            path="/:year"
            graphType={graphType}
            setSelectedCategory={(category) => goTo(`${path}${category}/`)}
            budget={budget}
            isMobile={isMobile}
            selectedCategory={selectedCategory}
            onHeightChange={(height) => setBudgetListHeight(height)}
            onClick={(id) => onClick(id)}
            hoveredItem={hoveredItem}
            arcVizInfoKeyboardHandler={(e) => arcVizInfoKeyboardHandler(e)}
            animate={false}
          />

          {!isMobile ? <HomeOverview
            path="/:year/:category"
            graphType={graphType}
            desktopButtonMouseEnterFn={onMouseOutFn}
            setSelectedCategory={(category) => goTo(`../${category}/`)}
            budget={budget}
            isMobile={isMobile}
            selectedCategory={selectedCategory}
            onHeightChange={(height) => setBudgetListHeight(height)}
            onClick={(id) => onClick(id)}
            hoveredItem={hoveredItem}
            arcVizInfoKeyboardHandler={(e) => arcVizInfoKeyboardHandler(e)}
            animate={wasDetailPage}
          /> : null}

          {isMobile ? <HomeDetail
            selectedCategory={selectedCategory}
            onClick={(id) => onClick(id)}
            path="/:year/:category/*"
            isMobile={isMobile}
            onMouseEnter={(item) => setHoveredItem(item)}
            onMouseLeave={() => setHoveredItem(null)}
            hoveredItem={hoveredItem}
            budget={currentBudget}
            totalBudget={budget[selectedCategory]?.total}
            onHeightChange={(height) => setBudgetListHeight(height)}
            animate={false}
          /> : <HomeDetail
            selectedCategory={selectedCategory}
            onClick={(id) => onClick(id)}
            path="/:year/:category/*"
            onMouseEnter={(item) => setHoveredItem(item)}
            onMouseLeave={() => setHoveredItem(null)}
            hoveredItem={hoveredItem}
            isMobile={isMobile}
            budget={currentBudget}
            totalBudget={budget[selectedCategory]?.total}
            onHeightChange={(height) => setBudgetListHeight(height)}
            animate={(wasDetailPage === false) && !isMobile}
          />}
        </FramerRouter>
      </div>

      <div
        className={HomeStyle.vizContainerWrapper}
        style={{
          opacity: graphType === 2 && !isDetailPage ? 0 : 1,
          display: graphType === 2 && !isDetailPage ? 'none' : '',
          top: isDetailPage ? "60px" : "0",
          height: isDetailPage ? "calc(100% - 60px)" : "100%"
        }}>
        <div ref={vizContainer}
             style={{
               position: isDetailPage ? "sticky" : "",
             }}
             className={HomeStyle.vizContainer}>
          {!isMobile && (typeof window !== `undefined`) ?
            <>
              <h3
                className={'visually-hidden'}>{selectedCategory === 'expenses' ? intl.formatMessage({id: 'home_buttonexpense'}) : intl.formatMessage({id: 'home_buttonrevenue'})}</h3>
              <ArcViz
                style={{position: "relative"}}
                key={selectedCategory}
                trail={categoryIdTrail}
                container={vizContainer}
                budget={budget[selectedCategory]}
                budgetCategory={selectedCategory}
                onClickItem={(id) => onClick(id)}
                onGoBack={goBack}
                onItemEnter={item => setHoveredItem(item)}
                onItemLeave={item => isDetailPage ? setHoveredItem(
                  null) : ""}
                hoveredItem={hoveredItem}
                onSegmentFocus={(item) => setHoveredItem(item)}
              />
            </>
            : null}

          {isMobile && isDetailPage ? <BarViz
            key={selectedCategory}
            trail={categoryIdTrail}
            container={vizContainer}
            budget={budget[selectedCategory]}
            onClickItem={index => {
              //todo: set ID
              if (budget[selectedCategory]) {
                return onClick(Object.values(
                  budget[selectedCategory].children)[index].category_id)
              }
            }}
            hoverItemIndex={null}
          /> : null}
        </div>
      </div>
    </div>
  </section>
}

export default HomeBudget
